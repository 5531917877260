





























































import { Component, Inject, Watch, Vue } from "vue-property-decorator";

import Card from "@/components/material/Card.vue";
import { TableHeader } from "@/components/layout/models/table.d";
import { Policy } from "@/models/library-maintenance.d";
import PolicyService from "@/services/policy-service";
import { getModule } from "vuex-module-decorators";
import AppState from "@/store/modules/app-module";
import LocalState from "@/store/modules/local-state-module";
import { VDataTableOptions, SnackbarOptions } from "@/models/form";
import EcSnackBar from "common-components/src/components/form/ec-snack-bar.vue";
import axios, { CancelTokenSource } from "axios";

import { Query } from "@/models/types";
import { formatSort } from "@/components/helper/sortHelpers";

const appState = getModule(AppState);
const localState = getModule(LocalState);

@Component({
  components: {
    Card,
    EcSnackBar,
  },
})
export default class PolicyListView extends Vue {
  @Inject() PolicyService!: PolicyService;

  headers: TableHeader[] = [];

  items: Policy[] = [];

  totalItems = 0;

  loading = false;

  page = 1;

  size = localState.defaultPageSize;

  sort: string[] = ["name,asc"];

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

  cancellationToken!: CancelTokenSource;

  get numberOfPages() {
    if (this.size === 0) return 1;
    return Math.max(1, Math.ceil(this.totalItems / this.size));
  }

  async mounted(): Promise<void> {
    this.headers = [
      {
        sortable: false,
        sortDirection: "",
        text: "Name",
        value: "name",
      },
      {
        sortable: false,
        sortDirection: "",
        text: "Description",
        value: "description",
      },
      {
        sortable: false,
        sortDirection: "",
        text: "Delete",
        value: "actions",
      },
      {
        sortable: false,
        sortDirection: "",
        text: "Enabled",
        value: "enabled",
      },
    ];
  }

  get apiFault() {
    return appState.apiFault;
  }

  @Watch("$route", { immediate: true })
  onRouteChange() {
    const { page = 1, size = this.size, sort = this.sort } = this.$route.query || {};

    this.page = +page;
    this.size = +size;
    this.sort = formatSort(sort);

    this.loadPage();
  }

  optionsUpdated(options: VDataTableOptions) {
    const { sortBy, sortDesc, page, itemsPerPage } = options;

    const sort = sortBy.map((by, index) => `${by},${sortDesc[index] ? "desc" : "asc"}`);

    const currentQuery = this.$route.query;
    const newQuery: Partial<Query> = {
      page: `${page}`,
      size: `${itemsPerPage}`,
      sort,
    };
    if (sort.length === 0) {
      delete newQuery.sort;
    }

    if (JSON.stringify(currentQuery) === JSON.stringify(newQuery)) return;

    this.$router.push({
      query: newQuery,
    });
  }

  async loadPage() {
    this.loading = true;

    const pagination = {
      page: this.page,
      size: this.size,
      sort: this.sort,
    };

    try {
      if (this.cancellationToken) this.cancellationToken.cancel("Reloaded list");
      this.cancellationToken = axios.CancelToken.source();

      const list = await this.PolicyService.listPolicies(pagination, this.cancellationToken.token);

      this.size = list.page.size;
      this.page = list.page.number;
      this.totalItems = list.page.totalElements;
      this.items = list._embedded.policies;

      localState.setDefaultPageSize(this.size);
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(error as string);
      }
    } finally {
      this.loading = false;
    }
  }

  async enableItem(item: Policy, enable: boolean) {
    try {
      const text = enable
        ? "If you enable this policy, it will be processed on subsequent bookings."
        : "If you disable this policy, it will not be processed on subsequent bookings.";

      appState.openDialog({
        title: enable ? "Enable Policy?" : "Disable Policy?",
        text: `<p>Are you sure?</p><p>${text}</p>`,
        actions: [
          {
            name: enable ? "Enable" : "Disable",
            color: "warning",
            handler: async () => {
              this.snackbarOptions = EcSnackBar.makeProgressOptions("Changing policy ...");
              this.PolicyService.setEnabledState(item.id, enable)
                .then(() => {
                  const local = item;
                  local.enabled = enable;

                  this.snackbarOptions.value = false;
                  this.$nextTick(() => {
                    this.snackbarOptions = EcSnackBar.makeSuccessfulOptions("Changed policy");
                  });
                })
                .catch((error: Error) => {
                  this.snackbarOptions.value = false;
                  this.$nextTick(() => {
                    this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(
                      `Failed to change policy: ${error.message}`
                    );
                  });
                });
            },
          },
          {
            name: "Cancel",
            color: "primary",
            handler: () => Promise.resolve(false),
          },
        ],
      });
    } catch (error) {
      this.snackbarOptions.value = false;
      this.$nextTick(() => {
        this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions("Failed to change policy");
      });
    }
  }

  async deleteItem(item: Policy) {
    try {
      appState.openDialog({
        title: "Delete Policy?",
        text: "<p>Are you sure?</p><p>If you delete this policy, this policy will no longer be run on subsequent bookings.</p>",
        actions: [
          {
            name: "Delete",
            color: "warning",
            handler: async () => {
              this.snackbarOptions = EcSnackBar.makeProgressOptions("Deleting policy ...");
              this.PolicyService.deletePolicy(item.id)
                .then(() => {
                  this.snackbarOptions.value = false;
                  this.$nextTick(() => {
                    this.snackbarOptions = EcSnackBar.makeSuccessfulOptions("Deleted policy");
                  });

                  this.loadPage();
                })
                .catch((error: Error) => {
                  this.snackbarOptions.value = false;
                  this.$nextTick(() => {
                    this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(
                      `Failed to delete policy: ${error.message}`
                    );
                  });
                });
            },
          },
          {
            name: "Cancel",
            color: "primary",
            handler: () => Promise.resolve(false),
          },
        ],
      });
    } catch (error) {
      this.snackbarOptions.value = false;
      this.$nextTick(() => {
        this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(
          `Failed to delete policy: ${error}`
        );
      });
    }
  }
}
